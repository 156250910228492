/*
File generated by js-routes 1.3.3
Based on Rails routes of Kassi::Application
 */

(function() {
  var DeprecatedBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, Utils, createGlobalJsRoutesObject, defaults, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message) {
    this.message = message;
  };

  ParameterMissing.prototype = new Error();

  defaults = {
    prefix: "",
    default_url_options: {}
  };

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  SpecialOptionsKey = "_options";

  DeprecatedBehavior = false;

  ReservedOptions = ['anchor', 'trailing_slash', 'host', 'port', 'protocol'];

  Utils = {
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    custom_serializer: null,
    serialize: function(object) {
      if ((this.custom_serializer != null) && this.get_object_type(this.custom_serializer) === "function") {
        return this.custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[SpecialOptionsKey];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[SpecialOptionsKey] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = DeprecatedBehavior || actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(defaults.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          if (value != null) {
            delete parameters[left];
            return this.path_identifier(value);
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      if (left.replace(/^\*/i, "") !== left) {
        route[1] = left = left.replace(/^\*/i, "");
      }
      value = parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      parameters[left] = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      return this.visit(route, parameters, optional);
    },
    get_prefix: function() {
      var prefix;
      prefix = defaults.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      protocol = route_defaults.protocol || Utils.current_protocol();
      hostname = route_defaults.host || window.location.hostname;
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + hostname + port;
    },
    has_location: function() {
      return typeof window !== 'undefined' && typeof window.location !== 'undefined';
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    }
  };

  createGlobalJsRoutesObject = function() {
    var namespace;
    namespace = function(mainRoot, namespaceString) {
      var current, parts;
      parts = (namespaceString ? namespaceString.split(".") : []);
      if (!parts.length) {
        return;
      }
      current = parts.shift();
      mainRoot[current] = mainRoot[current] || {};
      return namespace(mainRoot[current], parts.join("."));
    };
    namespace(root, "Routes");
    root.Routes = {
// about_infos => (/:locale)/infos/about(.:format)
  // function(options)
  about_infos_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"infos",false],[2,[7,"/",false],[2,[6,"about",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// accept_preauthorized_now_person_message => (/:locale)/:person_id/messages/:id/accept_preauthorized_now(.:format)
  // function(person_id, id, options)
  accept_preauthorized_now_person_message_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"accept_preauthorized_now",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// accept_preauthorized_person_message => (/:locale)/:person_id/messages/:id/accept_preauthorized(.:format)
  // function(person_id, id, options)
  accept_preauthorized_person_message_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"accept_preauthorized",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// accept_terms => (/:locale)/terms/accept(.:format)
  // function(options)
  accept_terms_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"terms",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// acceptance_preauthorized_person_message => (/:locale)/:person_id/messages/:id/acceptance_preauthorized(.:format)
  // function(person_id, id, options)
  acceptance_preauthorized_person_message_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"acceptance_preauthorized",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// access_denied => (/:locale)/community_memberships/access_denied(.:format)
  // function(options)
  access_denied_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[6,"access_denied",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// account_create_admin_community_paypal_preferences => (/:locale)/admin/communities/:community_id/paypal_preferences/account_create(.:format)
  // function(community_id, options)
  account_create_admin_community_paypal_preferences_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"paypal_preferences",false],[2,[7,"/",false],[2,[6,"account_create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// account_person_settings => (/:locale)/:person_id/settings/account(.:format)
  // function(person_id, options)
  account_person_settings_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// add_from_file_listing_images => (/:locale)/listing_images/add_from_file(.:format)
  // function(options)
  add_from_file_listing_images_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listing_images",false],[2,[7,"/",false],[2,[6,"add_from_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// add_from_file_listing_listing_images => (/:locale)/listings/:listing_id/listing_images/add_from_file(.:format)
  // function(listing_id, options)
  add_from_file_listing_listing_images_path: Utils.route([["locale",false],["listing_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"listing_images",false],[2,[7,"/",false],[2,[6,"add_from_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// add_from_url_listing_images => (/:locale)/listing_images/add_from_url(.:format)
  // function(options)
  add_from_url_listing_images_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listing_images",false],[2,[7,"/",false],[2,[6,"add_from_url",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// add_from_url_listing_listing_images => (/:locale)/listings/:listing_id/listing_images/add_from_url(.:format)
  // function(listing_id, options)
  add_from_url_listing_listing_images_path: Utils.route([["locale",false],["listing_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"listing_images",false],[2,[7,"/",false],[2,[6,"add_from_url",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// add_order_webhooks_email_orders => /webhooks/email_orders/add_order(.:format)
  // function(options)
  add_order_webhooks_email_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"email_orders",false],[2,[7,"/",false],[2,[6,"add_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin => (/:locale)/admin(.:format)
  // function(options)
  admin_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// admin_affiliate => (/:locale)/admin/affiliates/:id(.:format)
  // function(id, options)
  admin_affiliate_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"affiliates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_affiliate_download_payout_statement => (/:locale)/admin/affiliates/:affiliate_id/download_payout/:id(.:format)
  // function(affiliate_id, id, options)
  admin_affiliate_download_payout_statement_path: Utils.route([["locale",false],["affiliate_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"affiliates",false],[2,[7,"/",false],[2,[3,"affiliate_id",false],[2,[7,"/",false],[2,[6,"download_payout",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// admin_affiliates => (/:locale)/admin/affiliates(.:format)
  // function(options)
  admin_affiliates_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"affiliates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_categories => (/:locale)/admin/categories(.:format)
  // function(options)
  admin_categories_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_category => (/:locale)/admin/categories/:id(.:format)
  // function(id, options)
  admin_category_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_communities => (/:locale)/admin/communities(.:format)
  // function(options)
  admin_communities_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_community => (/:locale)/admin/communities/:id(.:format)
  // function(id, options)
  admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_community_community_membership => (/:locale)/admin/communities/:community_id/community_memberships/:id(.:format)
  // function(community_id, id, options)
  admin_community_community_membership_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// admin_community_community_memberships => (/:locale)/admin/communities/:community_id/community_memberships(.:format)
  // function(community_id, options)
  admin_community_community_memberships_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"community_memberships",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// admin_community_conversation => (/:locale)/admin/communities/:community_id/conversations/:id(.:format)
  // function(community_id, id, options)
  admin_community_conversation_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// admin_community_conversations => (/:locale)/admin/communities/:community_id/conversations(.:format)
  // function(community_id, options)
  admin_community_conversations_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"conversations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// admin_community_email => (/:locale)/admin/communities/:community_id/emails/:id(.:format)
  // function(community_id, id, options)
  admin_community_email_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// admin_community_emails => (/:locale)/admin/communities/:community_id/emails(.:format)
  // function(community_id, options)
  admin_community_emails_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// admin_community_invitations => (/:locale)/admin/communities/:community_id/invitations(.:format)
  // function(community_id, options)
  admin_community_invitations_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"invitations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// admin_community_listing => (/:locale)/admin/communities/:community_id/listings/:id(.:format)
  // function(community_id, id, options)
  admin_community_listing_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// admin_community_listings => (/:locale)/admin/communities/:community_id/listings(.:format)
  // function(community_id, options)
  admin_community_listings_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"listings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// admin_community_paypal_preferences => (/:locale)/admin/communities/:community_id/paypal_preferences(.:format)
  // function(community_id, options)
  admin_community_paypal_preferences_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"paypal_preferences",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// admin_community_seo_settings => (/:locale)/admin/community_seo_settings(.:format)
  // function(options)
  admin_community_seo_settings_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"community_seo_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_community_testimonial => (/:locale)/admin/communities/:community_id/testimonials/:id(.:format)
  // function(community_id, id, options)
  admin_community_testimonial_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"testimonials",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// admin_community_testimonials => (/:locale)/admin/communities/:community_id/testimonials(.:format)
  // function(community_id, options)
  admin_community_testimonials_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"testimonials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// admin_community_transaction => (/:locale)/admin/communities/:community_id/transactions/:id(.:format)
  // function(community_id, id, options)
  admin_community_transaction_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// admin_community_transactions => (/:locale)/admin/communities/:community_id/transactions(.:format)
  // function(community_id, options)
  admin_community_transactions_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"transactions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// admin_custom_field => (/:locale)/admin/custom_fields/:id(.:format)
  // function(id, options)
  admin_custom_field_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_custom_fields => (/:locale)/admin/custom_fields(.:format)
  // function(options)
  admin_custom_fields_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_details => (/:locale)/admin/details(.:format)
  // function(options)
  admin_details_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_details_edit => (/:locale)/admin/details/edit(.:format)
  // function(options)
  admin_details_edit_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"details",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_footer => (/:locale)/admin/footer(.:format)
  // function(options)
  admin_footer_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"footer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_footer_edit => (/:locale)/admin/footer/edit(.:format)
  // function(options)
  admin_footer_edit_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"footer",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_getting_started_guide => (/:locale)/admin/getting_started_guide(.:format)
  // function(options)
  admin_getting_started_guide_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_getting_started_guide_cover_photo => (/:locale)/admin/getting_started_guide/cover_photo(.:format)
  // function(options)
  admin_getting_started_guide_cover_photo_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"cover_photo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_getting_started_guide_filter => (/:locale)/admin/getting_started_guide/filter(.:format)
  // function(options)
  admin_getting_started_guide_filter_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"filter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_getting_started_guide_invitation => (/:locale)/admin/getting_started_guide/invitation(.:format)
  // function(options)
  admin_getting_started_guide_invitation_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_getting_started_guide_listing => (/:locale)/admin/getting_started_guide/listing(.:format)
  // function(options)
  admin_getting_started_guide_listing_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"listing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_getting_started_guide_payment => (/:locale)/admin/getting_started_guide/payment(.:format)
  // function(options)
  admin_getting_started_guide_payment_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_getting_started_guide_skip_payment => (/:locale)/admin/getting_started_guide/skip_payment(.:format)
  // function(options)
  admin_getting_started_guide_skip_payment_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"skip_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_getting_started_guide_slogan_and_description => (/:locale)/admin/getting_started_guide/slogan_and_description(.:format)
  // function(options)
  admin_getting_started_guide_slogan_and_description_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"slogan_and_description",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_listing_shape => (/:locale)/admin/listing_shapes/:id(.:format)
  // function(id, options)
  admin_listing_shape_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"listing_shapes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_listing_shapes => (/:locale)/admin/listing_shapes(.:format)
  // function(options)
  admin_listing_shapes_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"listing_shapes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_look_and_feel => (/:locale)/admin/look_and_feel(.:format)
  // function(options)
  admin_look_and_feel_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"look_and_feel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_look_and_feel_edit => (/:locale)/admin/look_and_feel/edit(.:format)
  // function(options)
  admin_look_and_feel_edit_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"look_and_feel",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_new_layout => (/:locale)/admin/new_layout(.:format)
  // function(options)
  admin_new_layout_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"new_layout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_payment_preferences => (/:locale)/admin/payment_preferences(.:format)
  // function(options)
  admin_payment_preferences_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"payment_preferences",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_paypal_preferences => (/:locale)/admin/paypal_preferences(.:format)
  // function(options)
  admin_paypal_preferences_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"paypal_preferences",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_paypal_preferences_account_create => (/:locale)/admin/paypal_preferences/account_create(.:format)
  // function(options)
  admin_paypal_preferences_account_create_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"paypal_preferences",false],[2,[7,"/",false],[2,[6,"account_create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_paypal_preferences_permissions_verified => (/:locale)/admin/paypal_preferences/permissions_verified(.:format)
  // function(options)
  admin_paypal_preferences_permissions_verified_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"paypal_preferences",false],[2,[7,"/",false],[2,[6,"permissions_verified",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_person_custom_field => (/:locale)/admin/user_fields/:id(.:format)
  // function(id, options)
  admin_person_custom_field_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user_fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_person_custom_fields => (/:locale)/admin/user_fields(.:format)
  // function(options)
  admin_person_custom_fields_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_promotion => (/:locale)/admin/promotions/:id(.:format)
  // function(id, options)
  admin_promotion_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"promotions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_promotions => (/:locale)/admin/promotions(.:format)
  // function(options)
  admin_promotions_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"promotions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_setting => (/:locale)/admin/settings(.:format)
  // function(options)
  admin_setting_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_topbar => (/:locale)/admin/topbar(.:format)
  // function(options)
  admin_topbar_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"topbar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// admin_topbar_edit => (/:locale)/admin/topbar/edit(.:format)
  // function(options)
  admin_topbar_edit_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"topbar",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_update_new_layout => (/:locale)/admin/new_layout(.:format)
  // function(options)
  admin_update_new_layout_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"new_layout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// analytics_admin_community => (/:locale)/admin/communities/:id/analytics(.:format)
  // function(id, options)
  analytics_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"analytics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// approve_admin_community_listing => (/:locale)/admin/communities/:community_id/listings/:id/approve(.:format)
  // function(community_id, id, options)
  approve_admin_community_listing_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// ask_billing_agreement_person_paypal_account => (/:locale)/:person_id/paypal_account/ask_billing_agreement(.:format)
  // function(person_id, options)
  ask_billing_agreement_person_paypal_account_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"paypal_account",false],[2,[7,"/",false],[2,[6,"ask_billing_agreement",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// ask_order_permission_person_paypal_account => (/:locale)/:person_id/paypal_account/ask_order_permission(.:format)
  // function(person_id, options)
  ask_order_permission_person_paypal_account_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"paypal_account",false],[2,[7,"/",false],[2,[6,"ask_order_permission",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// audits_admin_community_listing => (/:locale)/admin/communities/:community_id/listings/:id/audits(.:format)
  // function(community_id, id, options)
  audits_admin_community_listing_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"audits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// ban_admin_community_community_membership => (/:locale)/admin/communities/:community_id/community_memberships/:id/ban(.:format)
  // function(community_id, id, options)
  ban_admin_community_community_membership_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"ban",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// billing_agreement_cancel_person_paypal_account => (/:locale)/:person_id/paypal_account/billing_agreement_cancel(.:format)
  // function(person_id, options)
  billing_agreement_cancel_person_paypal_account_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"paypal_account",false],[2,[7,"/",false],[2,[6,"billing_agreement_cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// billing_agreement_success_person_paypal_account => (/:locale)/:person_id/paypal_account/billing_agreement_success(.:format)
  // function(person_id, options)
  billing_agreement_success_person_paypal_account_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"paypal_account",false],[2,[7,"/",false],[2,[6,"billing_agreement_success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// booked => (/:locale)/listings/:listing_id/booked(.:format)
  // function(listing_id, options)
  booked_path: Utils.route([["locale",false],["listing_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"booked",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// bounces => /bounces(.:format)
  // function(options)
  bounces_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bounces",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// brands => (/:locale)/brands(.:format)
  // function(options)
  brands_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"brands",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// browse => (/:locale)/browse/:long_tail_page_key(.:format)
  // function(long_tail_page_key, options)
  browse_path: Utils.route([["locale",false],["long_tail_page_key",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"browse",false],[2,[7,"/",false],[2,[3,"long_tail_page_key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// browse_listings => (/:locale)/listings/browse(.:format)
  // function(options)
  browse_listings_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[6,"browse",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// cancel_admin_community_transaction => (/:locale)/admin/communities/:community_id/transactions/:id/cancel(.:format)
  // function(community_id, id, options)
  cancel_admin_community_transaction_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// cancel_paypal_service_checkout_orders => (/:locale)/paypal_service/checkout_orders/cancel(.:format)
  // function(options)
  cancel_paypal_service_checkout_orders_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"paypal_service",false],[2,[7,"/",false],[2,[6,"checkout_orders",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// cancel_person_message => (/:locale)/:person_id/messages/:id/cancel(.:format)
  // function(person_id, id, options)
  cancel_person_message_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// cancel_person_registration => (/:locale)/people/cancel(.:format)
  // function(options)
  cancel_person_registration_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// cart_email_ok_webhooks_iterable => /webhooks/iterable/cart_email_ok(.:format)
  // function(options)
  cart_email_ok_webhooks_iterable_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"iterable",false],[2,[7,"/",false],[2,[6,"cart_email_ok",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// change_locale => /change_locale(.:format)
  // function(options)
  change_locale_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"change_locale",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// change_mistyped_email_sessions => (/:locale)/sessions/change_mistyped_email(.:format)
  // function(options)
  change_mistyped_email_sessions_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[6,"change_mistyped_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// check_email_availability_and_validity_people => (/:locale)/people/check_email_availability_and_validity(.:format)
  // function(options)
  check_email_availability_and_validity_people_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"check_email_availability_and_validity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// check_email_availability_people => (/:locale)/people/check_email_availability(.:format)
  // function(options)
  check_email_availability_people_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"check_email_availability",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// check_email_status_admin_community => (/:locale)/admin/communities/:id/check_email_status(.:format)
  // function(id, options)
  check_email_status_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"check_email_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// check_invitation_code_people => (/:locale)/people/check_invitation_code(.:format)
  // function(options)
  check_invitation_code_people_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"check_invitation_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// check_username_availability_person => (/:locale)/people/:id/check_username_availability(.:format)
  // function(id, options)
  check_username_availability_person_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"check_username_availability",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// claim_external_order => (/:locale)/orders/:token/claim(.:format)
  // function(token, options)
  claim_external_order_path: Utils.route([["locale",false],["token",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"token",false],[2,[7,"/",false],[2,[6,"claim",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// close_listings_admin_listing_shape => (/:locale)/admin/listing_shapes/:id/close_listings(.:format)
  // function(id, options)
  close_listings_admin_listing_shape_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"listing_shapes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"close_listings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// close_person_listing => (/:locale)/:person_id/listings/:id/close(.:format)
  // function(person_id, id, options)
  close_person_listing_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"close",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// collection_person => (/:locale)/:username/collections/:collection_id(.:format)
  // function(username, collection_id, options)
  collection_person_path: Utils.route([["locale",false],["username",true],["collection_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"username",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"collection_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// collections => (/:locale)/collections(.:format)
  // function(options)
  collections_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// collections_person => (/:locale)/:username/collections(.:format)
  // function(username, options)
  collections_person_path: Utils.route([["locale",false],["username",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"username",false],[2,[7,"/",false],[2,[6,"collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// common_update_admin_payment_preferences => (/:locale)/admin/payment_preferences/common_update(.:format)
  // function(options)
  common_update_admin_payment_preferences_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"payment_preferences",false],[2,[7,"/",false],[2,[6,"common_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// communities => /communities(.:format)
  // function(options)
  communities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"communities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// community_memberships_check_email_availability_and_validity => (/:locale)/community_memberships/check_email_availability_and_validity(.:format)
  // function(options)
  community_memberships_check_email_availability_and_validity_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[6,"check_email_availability_and_validity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// community_memberships_check_invitation_code => (/:locale)/community_memberships/check_invitation_code(.:format)
  // function(options)
  community_memberships_check_invitation_code_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[6,"check_invitation_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// community_not_available => /not_available(.:format)
  // function(options)
  community_not_available_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"not_available",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// community_not_found => /community_not_found(.:format)
  // function(options)
  community_not_found_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"community_not_found",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// concierge => (/:locale)/concierge(.:format)
  // function(options)
  concierge_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"concierge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// confirm_admin_community_transaction => (/:locale)/admin/communities/:community_id/transactions/:id/confirm(.:format)
  // function(community_id, id, options)
  confirm_admin_community_transaction_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// confirm_person_message => (/:locale)/:person_id/messages/:id/confirm(.:format)
  // function(person_id, id, options)
  confirm_person_message_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// confirmation => (/:locale)/people/confirmation(.:format)
  // function(options)
  confirmation_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// confirmation_pending => (/:locale)/confirmation_pending(.:format)
  // function(options)
  confirmation_pending_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"confirmation_pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// confirmation_person_message => (/:locale)/:person_id/messages/:id/confirmation(.:format)
  // function(person_id, id, options)
  confirmation_person_message_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// consent => (/:locale)/consent/:id(.:format)
  // function(id, options)
  consent_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"consent",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// consent_index => (/:locale)/consent(.:format)
  // function(options)
  consent_index_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"consent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// contact_person => (/:locale)/:username/contact(.:format)
  // function(username, options)
  contact_person_path: Utils.route([["locale",false],["username",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"username",false],[2,[7,"/",false],[2,[6,"contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// contact_to_listing => (/:locale)/listings/:listing_id/contact(.:format)
  // function(listing_id, options)
  contact_to_listing_path: Utils.route([["locale",false],["listing_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// create_contact => (/:locale)/listings/:listing_id/create_contact(.:format)
  // function(listing_id, options)
  create_contact_path: Utils.route([["locale",false],["listing_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"create_contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// create_person_payment_settings => (/:locale)/:person_id/settings/payments(.:format)
  // function(person_id, options)
  create_person_payment_settings_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// delayed_job => /delayed_job(.:format)
  // function(options)
  delayed_job_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delayed_job",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// delete_listing => (/:locale)/listings/:id/delete(.:format)
  // function(id, options)
  delete_listing_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// design => /design(.:format)
  // function(options)
  design_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"design",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// destroy_and_move_admin_category => (/:locale)/admin/categories/:id/destroy_and_move(.:format)
  // function(id, options)
  destroy_and_move_admin_category_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_and_move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// destroy_person_session => (/:locale)/people/sign_out(.:format)
  // function(options)
  destroy_person_session_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// disable_admin_payment_preference => (/:locale)/admin/payment_preferences/:payment_gateway/disable(.:format)
  // function(payment_gateway, options)
  disable_admin_payment_preference_path: Utils.route([["locale",false],["payment_gateway",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"payment_preferences",false],[2,[7,"/",false],[2,[3,"payment_gateway",false],[2,[7,"/",false],[2,[6,"disable",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// discount => (/:locale)/discount/:discount_percent(.:format)
  // function(discount_percent, options)
  discount_path: Utils.route([["locale",false],["discount_percent",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"discount",false],[2,[7,"/",false],[2,[3,"discount_percent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// discovery_with_locale => /:locale(.:format)
  // function(locale, options)
  discovery_with_locale_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// discovery_without_locale => /
  // function(options)
  discovery_without_locale_path: Utils.route([], {}, [7,"/",false]),
// dismiss_admin_community_transaction => (/:locale)/admin/communities/:community_id/transactions/:id/dismiss(.:format)
  // function(community_id, id, options)
  dismiss_admin_community_transaction_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"dismiss",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// dispute_admin_community_transaction => (/:locale)/admin/communities/:community_id/transactions/:id/dispute(.:format)
  // function(community_id, id, options)
  dispute_admin_community_transaction_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"dispute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// donate_listing => (/:locale)/listings/:id/donate(.:format)
  // function(id, options)
  donate_listing_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"donate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// donate_person_listing => (/:locale)/:person_id/listings/:id/donate(.:format)
  // function(person_id, id, options)
  donate_person_listing_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"donate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// donate_success_listing => (/:locale)/listings/:id/donate/success(.:format)
  // function(id, options)
  donate_success_listing_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"donate",false],[2,[7,"/",false],[2,[6,"success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_admin_affiliate => (/:locale)/admin/affiliates/:id/edit(.:format)
  // function(id, options)
  edit_admin_affiliate_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"affiliates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_admin_category => (/:locale)/admin/categories/:id/edit(.:format)
  // function(id, options)
  edit_admin_category_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_admin_community => (/:locale)/admin/communities/:id/edit(.:format)
  // function(id, options)
  edit_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_admin_community_community_membership => (/:locale)/admin/communities/:community_id/community_memberships/:id/edit(.:format)
  // function(community_id, id, options)
  edit_admin_community_community_membership_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// edit_admin_community_email => (/:locale)/admin/communities/:community_id/emails/:id/edit(.:format)
  // function(community_id, id, options)
  edit_admin_community_email_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// edit_admin_community_listing => (/:locale)/admin/communities/:community_id/listings/:id/edit(.:format)
  // function(community_id, id, options)
  edit_admin_community_listing_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// edit_admin_community_testimonial => (/:locale)/admin/communities/:community_id/testimonials/:id/edit(.:format)
  // function(community_id, id, options)
  edit_admin_community_testimonial_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"testimonials",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// edit_admin_custom_field => (/:locale)/admin/custom_fields/:id/edit(.:format)
  // function(id, options)
  edit_admin_custom_field_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_admin_listing_shape => (/:locale)/admin/listing_shapes/:id/edit(.:format)
  // function(id, options)
  edit_admin_listing_shape_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"listing_shapes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_admin_person_custom_field => (/:locale)/admin/user_fields/:id/edit(.:format)
  // function(id, options)
  edit_admin_person_custom_field_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user_fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_admin_promotion => (/:locale)/admin/promotions/:id/edit(.:format)
  // function(id, options)
  edit_admin_promotion_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"promotions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_consent => (/:locale)/consent/:id/edit(.:format)
  // function(id, options)
  edit_consent_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"consent",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_details_admin_community => (/:locale)/admin/communities/:id/edit_details(.:format)
  // function(id, options)
  edit_details_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_expiration_admin_custom_fields => (/:locale)/admin/custom_fields/edit_expiration(.:format)
  // function(options)
  edit_expiration_admin_custom_fields_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"edit_expiration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_form_content_listings => (/:locale)/listings/edit_form_content(.:format)
  // function(options)
  edit_form_content_listings_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[6,"edit_form_content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// edit_homepage => (/:locale)/homepage/:id/edit(.:format)
  // function(id, options)
  edit_homepage_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"homepage",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_info => (/:locale)/infos/:id/edit(.:format)
  // function(id, options)
  edit_info_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"infos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_listing => (/:locale)/listings/:id/edit(.:format)
  // function(id, options)
  edit_listing_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_listing_image => (/:locale)/listing_images/:id/edit(.:format)
  // function(id, options)
  edit_listing_image_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listing_images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_listing_listing_image => (/:locale)/listings/:listing_id/listing_images/:id/edit(.:format)
  // function(listing_id, id, options)
  edit_listing_listing_image_path: Utils.route([["locale",false],["listing_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"listing_images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// edit_location_admin_custom_fields => (/:locale)/admin/custom_fields/edit_location(.:format)
  // function(options)
  edit_location_admin_custom_fields_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"edit_location",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_look_and_feel_admin_community => (/:locale)/admin/communities/:id/edit_look_and_feel(.:format)
  // function(id, options)
  edit_look_and_feel_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_look_and_feel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_mercury_image => /mercury/images/:id/edit(.:format)
  // function(id, options)
  edit_mercury_image_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"mercury",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_paypal_service_checkout_order => (/:locale)/paypal_service/checkout_orders/:id/edit(.:format)
  // function(id, options)
  edit_paypal_service_checkout_order_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"paypal_service",false],[2,[7,"/",false],[2,[6,"checkout_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_person => (/:locale)/people/:id/edit(.:format)
  // function(id, options)
  edit_person_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_person_email => (/:locale)/:person_id/emails/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_email_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_person_followed_person => (/:locale)/:person_id/followed_people/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_followed_person_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"followed_people",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_person_follower => (/:locale)/:person_id/followers/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_follower_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"followers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_person_listing => (/:locale)/:person_id/listings/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_listing_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_person_message => (/:locale)/:person_id/messages/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_message_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_person_message_feedback => (/:locale)/:person_id/messages/:message_id/feedbacks/:id/edit(.:format)
  // function(person_id, message_id, id, options)
  edit_person_message_feedback_path: Utils.route([["locale",false],["person_id",true],["message_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"message_id",false],[2,[7,"/",false],[2,[6,"feedbacks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// edit_person_message_message => (/:locale)/:person_id/messages/:message_id/messages/:id/edit(.:format)
  // function(person_id, message_id, id, options)
  edit_person_message_message_path: Utils.route([["locale",false],["person_id",true],["message_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"message_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// edit_person_password => (/:locale)/people/password/edit(.:format)
  // function(options)
  edit_person_password_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_person_person_message => (/:locale)/:person_id/person_messages/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_person_message_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"person_messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_person_registration => (/:locale)/people/edit(.:format)
  // function(options)
  edit_person_registration_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// edit_person_settings => (/:locale)/:person_id/settings/edit(.:format)
  // function(person_id, options)
  edit_person_settings_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_person_testimonial => (/:locale)/:person_id/testimonials/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_testimonial_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"testimonials",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_price_admin_custom_fields => (/:locale)/admin/custom_fields/edit_price(.:format)
  // function(options)
  edit_price_admin_custom_fields_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"edit_price",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_product => (/:locale)/products/:id/edit(.:format)
  // function(id, options)
  edit_product_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_session => (/:locale)/sessions/:id/edit(.:format)
  // function(id, options)
  edit_session_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_sms => (/:locale)/sms/edit(.:format)
  // function(options)
  edit_sms_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"sms",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// edit_terms => (/:locale)/terms/edit(.:format)
  // function(options)
  edit_terms_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"terms",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// edit_text_instructions_admin_community => (/:locale)/admin/communities/:id/edit_text_instructions(.:format)
  // function(id, options)
  edit_text_instructions_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_text_instructions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_user_feedback => (/:locale)/user_feedbacks/:id/edit(.:format)
  // function(id, options)
  edit_user_feedback_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"user_feedbacks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_webhooks_email_orders => /webhooks/email_orders/edit(.:format)
  // function(options)
  edit_webhooks_email_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"email_orders",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_webhooks_iterable => /webhooks/iterable/edit(.:format)
  // function(options)
  edit_webhooks_iterable_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"iterable",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_webhooks_typeform => /webhooks/typeform/edit(.:format)
  // function(options)
  edit_webhooks_typeform_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"typeform",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// email_not_confirmed_webhooks_iterable => /webhooks/iterable/email_not_confirmed(.:format)
  // function(options)
  email_not_confirmed_webhooks_iterable_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"iterable",false],[2,[7,"/",false],[2,[6,"email_not_confirmed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// enable_admin_payment_preference => (/:locale)/admin/payment_preferences/:payment_gateway/enable(.:format)
  // function(payment_gateway, options)
  enable_admin_payment_preference_path: Utils.route([["locale",false],["payment_gateway",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"payment_preferences",false],[2,[7,"/",false],[2,[3,"payment_gateway",false],[2,[7,"/",false],[2,[6,"enable",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// error_not_acceptable => /406(.:format)
  // function(options)
  error_not_acceptable_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"406",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// error_not_found => /404(.:format)
  // function(options)
  error_not_found_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"404",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// export_admin_community_listings => (/:locale)/admin/communities/:community_id/listings/export(.:format)
  // function(community_id, options)
  export_admin_community_listings_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// export_admin_community_transactions => (/:locale)/admin/communities/:community_id/transactions/export(.:format)
  // function(community_id, options)
  export_admin_community_transactions_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// export_status_admin_community_listings => (/:locale)/admin/communities/:community_id/listings/export_status(.:format)
  // function(community_id, options)
  export_status_admin_community_listings_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[6,"export_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// export_status_admin_community_transactions => (/:locale)/admin/communities/:community_id/transactions/export_status(.:format)
  // function(community_id, options)
  export_status_admin_community_transactions_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[6,"export_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// external_order => (/:locale)/orders/:token(.:format)
  // function(token, options)
  external_order_path: Utils.route([["locale",false],["token",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// favourites_person => (/:locale)/:username/favourites(.:format)
  // function(username, options)
  favourites_person_path: Utils.route([["locale",false],["username",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"username",false],[2,[7,"/",false],[2,[6,"favourites",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// following_person => (/:locale)/:username/following(.:format)
  // function(username, options)
  following_person_path: Utils.route([["locale",false],["username",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"username",false],[2,[7,"/",false],[2,[6,"following",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// forgot_password => (/:locale)/forgot_password(.:format)
  // function(options)
  forgot_password_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"forgot_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// getting_started_admin_community => (/:locale)/admin/communities/:id/getting_started(.:format)
  // function(id, options)
  getting_started_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"getting_started",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// getting_started_guide_admin_community => (/:locale)/admin/communities/:id/getting_started_guide(.:format)
  // function(id, options)
  getting_started_guide_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// getting_started_guide_cover_photo_admin_community => (/:locale)/admin/communities/:id/getting_started_guide/cover_photo(.:format)
  // function(id, options)
  getting_started_guide_cover_photo_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"cover_photo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// getting_started_guide_filter_admin_community => (/:locale)/admin/communities/:id/getting_started_guide/filter(.:format)
  // function(id, options)
  getting_started_guide_filter_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"filter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// getting_started_guide_invitation_admin_community => (/:locale)/admin/communities/:id/getting_started_guide/invitation(.:format)
  // function(id, options)
  getting_started_guide_invitation_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// getting_started_guide_listing_admin_community => (/:locale)/admin/communities/:id/getting_started_guide/listing(.:format)
  // function(id, options)
  getting_started_guide_listing_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"listing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// getting_started_guide_paypal_admin_community => (/:locale)/admin/communities/:id/getting_started_guide/paypal(.:format)
  // function(id, options)
  getting_started_guide_paypal_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"paypal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// getting_started_guide_slogan_and_description_admin_community => (/:locale)/admin/communities/:id/getting_started_guide/slogan_and_description(.:format)
  // function(id, options)
  getting_started_guide_slogan_and_description_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"getting_started_guide",false],[2,[7,"/",false],[2,[6,"slogan_and_description",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// give_consent => (/:locale)/community_memberships/give_consent(.:format)
  // function(options)
  give_consent_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[6,"give_consent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// gmail_auth => /gmail_auth(.:format)
  // function(options)
  gmail_auth_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gmail_auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// gmail_auth_callback => /gmail_auth/oauth2callback(.:format)
  // function(options)
  gmail_auth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gmail_auth",false],[2,[7,"/",false],[2,[6,"oauth2callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// gone => /410(.:format)
  // function(options)
  gone_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"410",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// graphql => /graphql(.:format)
  // function(options)
  graphql_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"graphql",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// homepage => (/:locale)/homepage/:id(.:format)
  // function(id, options)
  homepage_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"homepage",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// homepage_index => (/:locale)/homepage(.:format)
  // function(options)
  homepage_index_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"homepage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// homepage_with_locale => /:locale(.:format)
  // function(locale, options)
  homepage_with_locale_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// how_to_use_infos => (/:locale)/infos/how_to_use(.:format)
  // function(options)
  how_to_use_infos_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"infos",false],[2,[7,"/",false],[2,[6,"how_to_use",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// image_status_listing_image => (/:locale)/listing_images/:id/image_status(.:format)
  // function(id, options)
  image_status_listing_image_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listing_images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"image_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// info => (/:locale)/infos/:id(.:format)
  // function(id, options)
  info_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"infos",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// infos => (/:locale)/infos(.:format)
  // function(options)
  infos_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"infos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// initiate_order => (/:locale)/listings/:listing_id/initiate(.:format)
  // function(listing_id, options)
  initiate_order_path: Utils.route([["locale",false],["listing_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"initiate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// initiate_order_old => (/:locale)/listings/:listing_id/initiate_old(.:format)
  // function(listing_id, options)
  initiate_order_old_path: Utils.route([["locale",false],["listing_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"initiate_old",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// initiated_order => (/:locale)/listings/:listing_id/initiated(.:format)
  // function(listing_id, options)
  initiated_order_path: Utils.route([["locale",false],["listing_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"initiated",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// int_api_listing_blocked_dates => /int_api/listings/:listing_id/blocked_dates(.:format)
  // function(listing_id, options)
  int_api_listing_blocked_dates_path: Utils.route([["listing_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"int_api",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"blocked_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// int_api_listing_bookings => /int_api/listings/:listing_id/bookings(.:format)
  // function(listing_id, options)
  int_api_listing_bookings_path: Utils.route([["listing_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"int_api",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"bookings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// invitations => (/:locale)/invitations(.:format)
  // function(options)
  invitations_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"invitations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// join_homepage_index => (/:locale)/homepage/join(.:format)
  // function(options)
  join_homepage_index_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"homepage",false],[2,[7,"/",false],[2,[6,"join",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// list_int_api_listing => /int_api/listings/:id/list(.:format)
  // function(id, options)
  list_int_api_listing_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"int_api",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// list_listing => (/:locale)/listings/:id/list(.:format)
  // function(id, options)
  list_listing_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// list_success_listing => (/:locale)/listings/:id/list/success(.:format)
  // function(id, options)
  list_success_listing_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"list",false],[2,[7,"/",false],[2,[6,"success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// listing => (/:locale)/listings/:id(.:format)
  // function(id, options)
  listing_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// listing_comment => (/:locale)/listings/:listing_id/comments/:id(.:format)
  // function(listing_id, id, options)
  listing_comment_path: Utils.route([["locale",false],["listing_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// listing_comments => (/:locale)/listings/:listing_id/comments(.:format)
  // function(listing_id, options)
  listing_comments_path: Utils.route([["locale",false],["listing_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// listing_details_webhooks_iterable => /webhooks/iterable/listing_details(.:format)
  // function(options)
  listing_details_webhooks_iterable_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"iterable",false],[2,[7,"/",false],[2,[6,"listing_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// listing_image => (/:locale)/listing_images/:id(.:format)
  // function(id, options)
  listing_image_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listing_images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// listing_images => (/:locale)/listing_images(.:format)
  // function(options)
  listing_images_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listing_images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// listing_listing_image => (/:locale)/listings/:listing_id/listing_images/:id(.:format)
  // function(listing_id, id, options)
  listing_listing_image_path: Utils.route([["locale",false],["listing_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"listing_images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// listing_listing_images => (/:locale)/listings/:listing_id/listing_images(.:format)
  // function(listing_id, options)
  listing_listing_images_path: Utils.route([["locale",false],["listing_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"listing_images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// listing_not_found => /listing_not_found(.:format)
  // function(options)
  listing_not_found_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"listing_not_found",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// listing_seo => (/:locale)/p/*category/*seo_title/:id(.:format)
  // function(category, seo_title, id, options)
  listing_seo_path: Utils.route([["locale",false],["category",true],["seo_title",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"p",false],[2,[7,"/",false],[2,[5,[3,"*category",false],false],[2,[7,"/",false],[2,[5,[3,"*seo_title",false],false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// listing_seo_incomplete => (/:locale)/p/*category/*seo_title(.:format)
  // function(category, seo_title, options)
  listing_seo_incomplete_path: Utils.route([["locale",false],["category",true],["seo_title",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"p",false],[2,[7,"/",false],[2,[5,[3,"*category",false],false],[2,[7,"/",false],[2,[5,[3,"*seo_title",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// listing_seo_old => (/:locale)/po/*category/*seo_title/:id(.:format)
  // function(category, seo_title, id, options)
  listing_seo_old_path: Utils.route([["locale",false],["category",true],["seo_title",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"po",false],[2,[7,"/",false],[2,[5,[3,"*category",false],false],[2,[7,"/",false],[2,[5,[3,"*seo_title",false],false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// listings => (/:locale)/listings(.:format)
  // function(options)
  listings_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// locations_json_listings => (/:locale)/listings/locations_json(.:format)
  // function(options)
  locations_json_listings_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[6,"locations_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// login => (/:locale)/login(.:format)
  // function(options)
  login_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// login_as_admin_person => (/:locale)/admin/people/:username/login_as(.:format)
  // function(username, options)
  login_as_admin_person_path: Utils.route([["locale",false],["username",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"username",false],[2,[7,"/",false],[2,[6,"login_as",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// login_from_checkout => (/:locale)/checkout/:listing_id/login(/*path)(.:format)
  // function(listing_id, options)
  login_from_checkout_path: Utils.route([["locale",false],["listing_id",true],["path",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"login",false],[2,[1,[2,[7,"/",false],[5,[3,"*path",false],false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// logout => (/:locale)/logout(.:format)
  // function(options)
  logout_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// menu_links_admin_community => (/:locale)/admin/communities/:id/menu_links(.:format)
  // function(id, options)
  menu_links_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"menu_links",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// mercury_engine => /
  // function(options)
  mercury_engine_path: Utils.route([], {}, [7,"/",false]),
// mercury_engine.mercury_editor => //editor(/*requested_uri)(.:format)
  // function(options)
  mercury_engine_mercury_editor_path: Utils.route([["requested_uri",false],["format",false]], {}, [2,[2,[7,"/",false],[7,"/",false]],[2,[6,"editor",false],[2,[1,[2,[7,"/",false],[5,[3,"*requested_uri",false],false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// mercury_image => /mercury/images/:id(.:format)
  // function(id, options)
  mercury_image_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"mercury",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mercury_images => /mercury/images(.:format)
  // function(options)
  mercury_images_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mercury",false],[2,[7,"/",false],[2,[6,"images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mercury_update => (/:locale)/mercury_update(.:format)
  // function(options)
  mercury_update_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"mercury_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// message_arrived_sms => (/:locale)/sms/message_arrived(.:format)
  // function(options)
  message_arrived_sms_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"sms",false],[2,[7,"/",false],[2,[6,"message_arrived",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// more_listings_listings => (/:locale)/listings/more_listings(.:format)
  // function(options)
  more_listings_listings_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[6,"more_listings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// move_to_top_person_listing => (/:locale)/:person_id/listings/:id/move_to_top(.:format)
  // function(person_id, id, options)
  move_to_top_person_listing_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_to_top",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_admin_affiliate => (/:locale)/admin/affiliates/new(.:format)
  // function(options)
  new_admin_affiliate_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"affiliates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_admin_category => (/:locale)/admin/categories/new(.:format)
  // function(options)
  new_admin_category_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_admin_community => (/:locale)/admin/communities/new(.:format)
  // function(options)
  new_admin_community_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_admin_community_community_membership => (/:locale)/admin/communities/:community_id/community_memberships/new(.:format)
  // function(community_id, options)
  new_admin_community_community_membership_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_admin_community_email => (/:locale)/admin/communities/:community_id/emails/new(.:format)
  // function(community_id, options)
  new_admin_community_email_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_admin_community_testimonial => (/:locale)/admin/communities/:community_id/testimonials/new(.:format)
  // function(community_id, options)
  new_admin_community_testimonial_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"testimonials",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_admin_custom_field => (/:locale)/admin/custom_fields/new(.:format)
  // function(options)
  new_admin_custom_field_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_admin_listing_shape => (/:locale)/admin/listing_shapes/new(.:format)
  // function(options)
  new_admin_listing_shape_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"listing_shapes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_admin_person_custom_field => (/:locale)/admin/user_fields/new(.:format)
  // function(options)
  new_admin_person_custom_field_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user_fields",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_admin_promotion => (/:locale)/admin/promotions/new(.:format)
  // function(options)
  new_admin_promotion_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"promotions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_community => /communities/new(.:format)
  // function(options)
  new_community_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_consent => (/:locale)/consent/new(.:format)
  // function(options)
  new_consent_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"consent",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_form_content_listings => (/:locale)/listings/new_form_content(.:format)
  // function(options)
  new_form_content_listings_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[6,"new_form_content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_form_content_person_listings => (/:locale)/:person_id/listings/new_form_content(.:format)
  // function(person_id, options)
  new_form_content_person_listings_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[6,"new_form_content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_homepage => (/:locale)/homepage/new(.:format)
  // function(options)
  new_homepage_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"homepage",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_info => (/:locale)/infos/new(.:format)
  // function(options)
  new_info_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"infos",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_invitation => (/:locale)/invitations/new(.:format)
  // function(options)
  new_invitation_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_listing => (/:locale)/listings/new(.:format)
  // function(options)
  new_listing_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_listing_image => (/:locale)/listing_images/new(.:format)
  // function(options)
  new_listing_image_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listing_images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_listing_listing_image => (/:locale)/listings/:listing_id/listing_images/new(.:format)
  // function(listing_id, options)
  new_listing_listing_image_path: Utils.route([["locale",false],["listing_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"listing_images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_mercury_image => /mercury/images/new(.:format)
  // function(options)
  new_mercury_image_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mercury",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_paypal_service_checkout_order => (/:locale)/paypal_service/checkout_orders/new(.:format)
  // function(options)
  new_paypal_service_checkout_order_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"paypal_service",false],[2,[7,"/",false],[2,[6,"checkout_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_person => (/:locale)/people/new(.:format)
  // function(options)
  new_person_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_person_email => (/:locale)/:person_id/emails/new(.:format)
  // function(person_id, options)
  new_person_email_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_person_followed_person => (/:locale)/:person_id/followed_people/new(.:format)
  // function(person_id, options)
  new_person_followed_person_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"followed_people",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_person_follower => (/:locale)/:person_id/followers/new(.:format)
  // function(person_id, options)
  new_person_follower_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"followers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_person_listing => (/:locale)/:person_id/listings/new(.:format)
  // function(person_id, options)
  new_person_listing_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_person_message => (/:locale)/:person_id/messages/new(.:format)
  // function(person_id, options)
  new_person_message_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_person_message_feedback => (/:locale)/:person_id/messages/:message_id/feedbacks/new(.:format)
  // function(person_id, message_id, options)
  new_person_message_feedback_path: Utils.route([["locale",false],["person_id",true],["message_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"message_id",false],[2,[7,"/",false],[2,[6,"feedbacks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_person_message_message => (/:locale)/:person_id/messages/:message_id/messages/new(.:format)
  // function(person_id, message_id, options)
  new_person_message_message_path: Utils.route([["locale",false],["person_id",true],["message_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"message_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_person_password => (/:locale)/people/password/new(.:format)
  // function(options)
  new_person_password_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_person_person_message => (/:locale)/:person_id/person_messages/new(.:format)
  // function(person_id, options)
  new_person_person_message_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"person_messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_person_registration => (/:locale)/people/sign_up(.:format)
  // function(options)
  new_person_registration_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_person_session => (/:locale)/people/login(.:format)
  // function(options)
  new_person_session_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_person_settings => (/:locale)/:person_id/settings/new(.:format)
  // function(person_id, options)
  new_person_settings_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_person_testimonial => (/:locale)/:person_id/testimonials/new(.:format)
  // function(person_id, options)
  new_person_testimonial_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"testimonials",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_person_transaction => (/:locale)/:person_id/transactions/new(.:format)
  // function(person_id, options)
  new_person_transaction_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_product => (/:locale)/products/new(.:format)
  // function(options)
  new_product_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_request_without_locale => /listings/new/:type(.:format)
  // function(type, options)
  new_request_without_locale_path: Utils.route([["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_session => (/:locale)/sessions/new(.:format)
  // function(options)
  new_session_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_sms => (/:locale)/sms/new(.:format)
  // function(options)
  new_sms_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"sms",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_terms => (/:locale)/terms/new(.:format)
  // function(options)
  new_terms_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"terms",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_transaction => (/:locale)/transactions/new(.:format)
  // function(options)
  new_transaction_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_unskip_admin_community_testimonials => (/:locale)/admin/communities/:community_id/testimonials/new_unskip(.:format)
  // function(community_id, options)
  new_unskip_admin_community_testimonials_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"testimonials",false],[2,[7,"/",false],[2,[6,"new_unskip",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_user_feedback => (/:locale)/user_feedbacks/new(.:format)
  // function(options)
  new_user_feedback_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"user_feedbacks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_webhooks_email_orders => /webhooks/email_orders/new(.:format)
  // function(options)
  new_webhooks_email_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"email_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_webhooks_iterable => /webhooks/iterable/new(.:format)
  // function(options)
  new_webhooks_iterable_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"iterable",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_webhooks_typeform => /webhooks/typeform/new(.:format)
  // function(options)
  new_webhooks_typeform_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"typeform",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// news_infos => (/:locale)/infos/news(.:format)
  // function(options)
  news_infos_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"infos",false],[2,[7,"/",false],[2,[6,"news",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// order_admin_categories => (/:locale)/admin/categories/order(.:format)
  // function(options)
  order_admin_categories_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// order_admin_custom_fields => (/:locale)/admin/custom_fields/order(.:format)
  // function(options)
  order_admin_custom_fields_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// order_admin_listing_shapes => (/:locale)/admin/listing_shapes/order(.:format)
  // function(options)
  order_admin_listing_shapes_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"listing_shapes",false],[2,[7,"/",false],[2,[6,"order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// order_admin_person_custom_fields => (/:locale)/admin/user_fields/order(.:format)
  // function(options)
  order_admin_person_custom_fields_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user_fields",false],[2,[7,"/",false],[2,[6,"order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// paypal_account_settings_payment => (/:locale)/:person_id/settings/payments/paypal_account(.:format)
  // function(person_id, options)
  paypal_account_settings_payment_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"paypal_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// paypal_ipn_hook => /webhooks/paypal_ipn(.:format)
  // function(options)
  paypal_ipn_hook_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"paypal_ipn",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// paypal_op_status => (/:locale)/transactions/op_status/:process_token(.:format)
  // function(process_token, options)
  paypal_op_status_path: Utils.route([["locale",false],["process_token",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[6,"op_status",false],[2,[7,"/",false],[2,[3,"process_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// paypal_service_checkout_order => (/:locale)/paypal_service/checkout_orders/:id(.:format)
  // function(id, options)
  paypal_service_checkout_order_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"paypal_service",false],[2,[7,"/",false],[2,[6,"checkout_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// paypal_service_checkout_orders => (/:locale)/paypal_service/checkout_orders(.:format)
  // function(options)
  paypal_service_checkout_orders_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"paypal_service",false],[2,[7,"/",false],[2,[6,"checkout_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// pending_consent => (/:locale)/community_memberships/pending_consent(.:format)
  // function(options)
  pending_consent_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[6,"pending_consent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// pending_external_order => (/:locale)/orders/:token/pending(.:format)
  // function(token, options)
  pending_external_order_path: Utils.route([["locale",false],["token",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"token",false],[2,[7,"/",false],[2,[6,"pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// people => (/:locale)/people(.:format)
  // function(options)
  people_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// people_confirmation => (/:locale)/people/confirmation(.:format)
  // function(options)
  people_confirmation_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// people_sign_up => (/:locale)/people/sign_up(.:format)
  // function(options)
  people_sign_up_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// permissions_verified_admin_community_paypal_preferences => (/:locale)/admin/communities/:community_id/paypal_preferences/permissions_verified(.:format)
  // function(community_id, options)
  permissions_verified_admin_community_paypal_preferences_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"paypal_preferences",false],[2,[7,"/",false],[2,[6,"permissions_verified",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// permissions_verified_person_paypal_account => (/:locale)/:person_id/paypal_account/permissions_verified(.:format)
  // function(person_id, options)
  permissions_verified_person_paypal_account_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"paypal_account",false],[2,[7,"/",false],[2,[6,"permissions_verified",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// person => (/:locale)/:username(.:format)
  // function(username, options)
  person_path: Utils.route([["locale",false],["username",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"username",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// person_email => (/:locale)/:person_id/emails/:id(.:format)
  // function(person_id, id, options)
  person_email_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// person_emails => (/:locale)/:person_id/emails(.:format)
  // function(person_id, options)
  person_emails_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// person_facebook_omniauth_authorize => /people/auth/facebook(.:format)
  // function(options)
  person_facebook_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"facebook",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// person_facebook_omniauth_callback => /people/auth/facebook/callback(.:format)
  // function(options)
  person_facebook_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"facebook",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// person_followed_people => (/:locale)/:person_id/followed_people(.:format)
  // function(person_id, options)
  person_followed_people_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"followed_people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// person_followed_person => (/:locale)/:person_id/followed_people/:id(.:format)
  // function(person_id, id, options)
  person_followed_person_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"followed_people",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// person_follower => (/:locale)/:person_id/followers/:id(.:format)
  // function(person_id, id, options)
  person_follower_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"followers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// person_followers => (/:locale)/:person_id/followers(.:format)
  // function(person_id, options)
  person_followers_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"followers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// person_google_oauth2_omniauth_authorize => /people/auth/google_oauth2(.:format)
  // function(options)
  person_google_oauth2_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"google_oauth2",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// person_google_oauth2_omniauth_callback => /people/auth/google_oauth2/callback(.:format)
  // function(options)
  person_google_oauth2_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"google_oauth2",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// person_inbox => (/:locale)/:person_id/inbox(.:format)
  // function(person_id, options)
  person_inbox_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"inbox",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// person_linkedin_omniauth_authorize => /people/auth/linkedin(.:format)
  // function(options)
  person_linkedin_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"linkedin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// person_linkedin_omniauth_callback => /people/auth/linkedin/callback(.:format)
  // function(options)
  person_linkedin_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"linkedin",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// person_listing => (/:locale)/:person_id/listings/:id(.:format)
  // function(person_id, id, options)
  person_listing_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// person_listings => (/:locale)/:person_id/listings(.:format)
  // function(person_id, options)
  person_listings_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"listings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// person_message => (/:locale)/:person_id/messages/:id(.:format)
  // function(person_id, id, options)
  person_message_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// person_message_feedback => (/:locale)/:person_id/messages/:message_id/feedbacks/:id(.:format)
  // function(person_id, message_id, id, options)
  person_message_feedback_path: Utils.route([["locale",false],["person_id",true],["message_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"message_id",false],[2,[7,"/",false],[2,[6,"feedbacks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// person_message_feedbacks => (/:locale)/:person_id/messages/:message_id/feedbacks(.:format)
  // function(person_id, message_id, options)
  person_message_feedbacks_path: Utils.route([["locale",false],["person_id",true],["message_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"message_id",false],[2,[7,"/",false],[2,[6,"feedbacks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// person_message_message => (/:locale)/:person_id/messages/:message_id/messages/:id(.:format)
  // function(person_id, message_id, id, options)
  person_message_message_path: Utils.route([["locale",false],["person_id",true],["message_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"message_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// person_message_messages => (/:locale)/:person_id/messages/:message_id/messages(.:format)
  // function(person_id, message_id, options)
  person_message_messages_path: Utils.route([["locale",false],["person_id",true],["message_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"message_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// person_messages => (/:locale)/:person_id/messages(.:format)
  // function(person_id, options)
  person_messages_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// person_password => (/:locale)/people/password(.:format)
  // function(options)
  person_password_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// person_payment_settings => (/:locale)/:person_id/settings/payments(.:format)
  // function(person_id, options)
  person_payment_settings_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// person_person_message => (/:locale)/:person_id/person_messages/:id(.:format)
  // function(person_id, id, options)
  person_person_message_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"person_messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// person_person_messages => (/:locale)/:person_id/person_messages(.:format)
  // function(person_id, options)
  person_person_messages_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"person_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// person_registration => (/:locale)/people(.:format)
  // function(options)
  person_registration_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// person_session => (/:locale)/people/login(.:format)
  // function(options)
  person_session_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// person_settings => (/:locale)/:person_id/settings(.:format)
  // function(person_id, options)
  person_settings_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// person_stripe_account => (/:locale)/:person_id/stripe_account(.:format)
  // function(person_id, options)
  person_stripe_account_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"stripe_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// person_testimonial => (/:locale)/:person_id/testimonials/:id(.:format)
  // function(person_id, id, options)
  person_testimonial_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"testimonials",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// person_testimonials => (/:locale)/:person_id/testimonials(.:format)
  // function(person_id, options)
  person_testimonials_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"testimonials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// person_transaction => (/:locale)/:person_id/transactions/:id(.:format)
  // function(person_id, id, options)
  person_transaction_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// person_transactions => (/:locale)/:person_id/transactions(.:format)
  // function(person_id, options)
  person_transactions_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"transactions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// posting_allowed_admin_community_community_memberships => (/:locale)/admin/communities/:community_id/community_memberships/posting_allowed(.:format)
  // function(community_id, options)
  posting_allowed_admin_community_community_memberships_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[6,"posting_allowed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// preferences_update_admin_community_paypal_preferences => (/:locale)/admin/communities/:community_id/paypal_preferences/preferences_update(.:format)
  // function(community_id, options)
  preferences_update_admin_community_paypal_preferences_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"paypal_preferences",false],[2,[7,"/",false],[2,[6,"preferences_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// privacy_infos => (/:locale)/infos/privacy(.:format)
  // function(options)
  privacy_infos_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"infos",false],[2,[7,"/",false],[2,[6,"privacy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// product => (/:locale)/products/:id(.:format)
  // function(id, options)
  product_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// product_success => (/:locale)/products/:product_id/success(.:format)
  // function(product_id, options)
  product_success_path: Utils.route([["locale",false],["product_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// products => (/:locale)/products(.:format)
  // function(options)
  products_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// promote_admin_admin_community_community_memberships => (/:locale)/admin/communities/:community_id/community_memberships/promote_admin(.:format)
  // function(community_id, options)
  promote_admin_admin_community_community_memberships_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[6,"promote_admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// rails_blob_representation => /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// received_person_messages => (/:locale)/:person_id/messages/received(.:format)
  // function(person_id, options)
  received_person_messages_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"received",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// refund_admin_community_transaction => (/:locale)/admin/communities/:community_id/transactions/:id/refund(.:format)
  // function(community_id, id, options)
  refund_admin_community_transaction_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refund",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// reject_admin_community_listing => (/:locale)/admin/communities/:community_id/listings/:id/reject(.:format)
  // function(community_id, id, options)
  reject_admin_community_listing_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reject",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// reject_preauthorized_person_message => (/:locale)/:person_id/messages/:id/reject_preauthorized(.:format)
  // function(person_id, id, options)
  reject_preauthorized_person_message_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reject_preauthorized",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// remove_admin_category => (/:locale)/admin/categories/:id/remove(.:format)
  // function(id, options)
  remove_admin_category_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// reorder_listing_listing_images => (/:locale)/listings/:listing_id/listing_images/reorder(.:format)
  // function(listing_id, options)
  reorder_listing_listing_images_path: Utils.route([["locale",false],["listing_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"listing_images",false],[2,[7,"/",false],[2,[6,"reorder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// request_new_password_sessions => (/:locale)/sessions/request_new_password(.:format)
  // function(options)
  request_new_password_sessions_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[6,"request_new_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// resend_confirmation_admin_community_community_membership => (/:locale)/admin/communities/:community_id/community_memberships/:id/resend_confirmation(.:format)
  // function(community_id, id, options)
  resend_confirmation_admin_community_community_membership_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resend_confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// resend_verification_email_admin_community => (/:locale)/admin/communities/:id/resend_verification_email(.:format)
  // function(id, options)
  resend_verification_email_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resend_verification_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// review_terms => (/:locale)/terms/review(.:format)
  // function(options)
  review_terms_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"terms",false],[2,[7,"/",false],[2,[6,"review",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// reviews_person => (/:locale)/:username/reviews(.:format)
  // function(username, options)
  reviews_person_path: Utils.route([["locale",false],["username",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"username",false],[2,[7,"/",false],[2,[6,"reviews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// search => (/:locale)/s(.:format)
  // function(options)
  search_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"s",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// search_brand => (/:locale)/b/:brand(.:format)
  // function(brand, options)
  search_brand_path: Utils.route([["locale",false],["brand",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"b",false],[2,[7,"/",false],[2,[3,"brand",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// search_collection => (/:locale)/collections/:collection_id(.:format)
  // function(collection_id, options)
  search_collection_path: Utils.route([["locale",false],["collection_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"collection_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// search_designer => (/:locale)/designer(.:format)
  // function(options)
  search_designer_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"designer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// search_kids => (/:locale)/kids(.:format)
  // function(options)
  search_kids_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"kids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// search_mens => (/:locale)/mens(.:format)
  // function(options)
  search_mens_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"mens",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// search_womens => (/:locale)/womens(.:format)
  // function(options)
  search_womens_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"womens",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// send_confirmation_person_email => (/:locale)/:person_id/emails/:id/send_confirmation(.:format)
  // function(person_id, id, options)
  send_confirmation_person_email_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// send_verification_person_stripe_account => (/:locale)/:person_id/stripe_account/send_verification(.:format)
  // function(person_id, options)
  send_verification_person_stripe_account_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"stripe_account",false],[2,[7,"/",false],[2,[6,"send_verification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// session => (/:locale)/sessions/:id(.:format)
  // function(id, options)
  session_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// sessions => (/:locale)/sessions(.:format)
  // function(options)
  sessions_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"sessions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// settings_admin_community => (/:locale)/admin/communities/:id/settings(.:format)
  // function(id, options)
  settings_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// ship_person_message => (/:locale)/:person_id/messages/:id/ship(.:format)
  // function(person_id, id, options)
  ship_person_message_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"ship",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// show_in_updates_email_person_listing => (/:locale)/:person_id/listings/:id/show_in_updates_email(.:format)
  // function(person_id, id, options)
  show_in_updates_email_person_listing_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_in_updates_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// sign_in_homepage_index => (/:locale)/homepage/sign_in(.:format)
  // function(options)
  sign_in_homepage_index_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"homepage",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// sign_up => (/:locale)/signup(.:format)
  // function(options)
  sign_up_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"signup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// single_conversation => (/:locale)/:person_id/messages/:conversation_type/:id(.:format)
  // function(person_id, conversation_type, id, options)
  single_conversation_path: Utils.route([["locale",false],["person_id",true],["conversation_type",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"conversation_type",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// skip_person_message_feedbacks => (/:locale)/:person_id/messages/:message_id/feedbacks/skip(.:format)
  // function(person_id, message_id, options)
  skip_person_message_feedbacks_path: Utils.route([["locale",false],["person_id",true],["message_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"message_id",false],[2,[7,"/",false],[2,[6,"feedbacks",false],[2,[7,"/",false],[2,[6,"skip",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// sms => (/:locale)/sms(.:format)
  // function(options)
  sms_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"sms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// social_media_admin_community => (/:locale)/admin/communities/:id/social_media(.:format)
  // function(id, options)
  social_media_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"social_media",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// sort_listings_person => (/:locale)/:username/sort_listings(.:format)
  // function(username, options)
  sort_listings_person_path: Utils.route([["locale",false],["username",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"username",false],[2,[7,"/",false],[2,[6,"sort_listings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// stripe_confirm_intent_listing_preauthorize_transaction => (/:locale)/listings/:listing_id/preauthorize_transactions/:id/stripe_confirm_intent(.:format)
  // function(listing_id, id, options)
  stripe_confirm_intent_listing_preauthorize_transaction_path: Utils.route([["locale",false],["listing_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"preauthorize_transactions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"stripe_confirm_intent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// stripe_failed_intent_listing_preauthorize_transaction => (/:locale)/listings/:listing_id/preauthorize_transactions/:id/stripe_failed_intent(.:format)
  // function(listing_id, id, options)
  stripe_failed_intent_listing_preauthorize_transaction_path: Utils.route([["locale",false],["listing_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"listing_id",false],[2,[7,"/",false],[2,[6,"preauthorize_transactions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"stripe_failed_intent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// success_paypal_service_checkout_orders => (/:locale)/paypal_service/checkout_orders/success(.:format)
  // function(options)
  success_paypal_service_checkout_orders_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"paypal_service",false],[2,[7,"/",false],[2,[6,"checkout_orders",false],[2,[7,"/",false],[2,[6,"success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// success_processed_paypal_service_checkout_orders => (/:locale)/paypal_service/checkout_orders/success_processed(.:format)
  // function(options)
  success_processed_paypal_service_checkout_orders_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"paypal_service",false],[2,[7,"/",false],[2,[6,"checkout_orders",false],[2,[7,"/",false],[2,[6,"success_processed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// survey_response_webhooks_typeform => /webhooks/typeform/survey_response(.:format)
  // function(options)
  survey_response_webhooks_typeform_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"typeform",false],[2,[7,"/",false],[2,[6,"survey_response",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// telemetries => /telemetries(.:format)
  // function(options)
  telemetries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"telemetries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// terms => (/:locale)/terms(.:format)
  // function(options)
  terms_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"terms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// terms_infos => (/:locale)/infos/terms(.:format)
  // function(options)
  terms_infos_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"infos",false],[2,[7,"/",false],[2,[6,"terms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// timeout_external_orders => (/:locale)/orders/timeout(.:format)
  // function(options)
  timeout_external_orders_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"timeout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// toggle_listing_collections => (/:locale)/collections/toggle_listing(.:format)
  // function(options)
  toggle_listing_collections_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"toggle_listing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// topbar_admin_community => (/:locale)/admin/communities/:id/topbar(.:format)
  // function(id, options)
  topbar_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"topbar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// transaction_created => (/:locale)/transactions/created/:transaction_id(.:format)
  // function(transaction_id, options)
  transaction_created_path: Utils.route([["locale",false],["transaction_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[6,"created",false],[2,[7,"/",false],[2,[3,"transaction_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// transaction_finalize_processed => (/:locale)/transactions/finalize_processed/:process_token(.:format)
  // function(process_token, options)
  transaction_finalize_processed_path: Utils.route([["locale",false],["process_token",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[6,"finalize_processed",false],[2,[7,"/",false],[2,[3,"process_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// transaction_op_status => (/:locale)/transactions/transaction_op_status/:process_token(.:format)
  // function(process_token, options)
  transaction_op_status_path: Utils.route([["locale",false],["process_token",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"transactions",false],[2,[7,"/",false],[2,[6,"transaction_op_status",false],[2,[7,"/",false],[2,[3,"process_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// transactional_email => /transactional_emails/:tracking_code(.:format)
  // function(tracking_code, options)
  transactional_email_path: Utils.route([["tracking_code",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"transactional_emails",false],[2,[7,"/",false],[2,[3,"tracking_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// transactions_person_settings => (/:locale)/:person_id/settings/transactions(.:format)
  // function(person_id, options)
  transactions_person_settings_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"transactions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// unban_admin_community_community_membership => (/:locale)/admin/communities/:community_id/community_memberships/:id/unban(.:format)
  // function(community_id, id, options)
  unban_admin_community_community_membership_path: Utils.route([["locale",false],["community_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"community_memberships",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unban",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// undo_donate_person_listing => (/:locale)/:person_id/listings/:id/undo_donate(.:format)
  // function(person_id, id, options)
  undo_donate_person_listing_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"undo_donate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// undo_sold_person_listing => (/:locale)/:person_id/listings/:id/undo_sold(.:format)
  // function(person_id, id, options)
  undo_sold_person_listing_path: Utils.route([["locale",false],["person_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"undo_sold",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// unskip_admin_community_testimonials => (/:locale)/admin/communities/:community_id/testimonials/unskip(.:format)
  // function(community_id, options)
  unskip_admin_community_testimonials_path: Utils.route([["locale",false],["community_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"community_id",false],[2,[7,"/",false],[2,[6,"testimonials",false],[2,[7,"/",false],[2,[6,"unskip",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// unsubscribe_invitations => (/:locale)/invitations/unsubscribe(.:format)
  // function(options)
  unsubscribe_invitations_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[6,"unsubscribe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// unsubscribe_person_settings => (/:locale)/:person_id/settings/unsubscribe(.:format)
  // function(person_id, options)
  unsubscribe_person_settings_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"unsubscribe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// update_blocked_dates_int_api_listing => /int_api/listings/:id/update_blocked_dates(.:format)
  // function(id, options)
  update_blocked_dates_int_api_listing_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"int_api",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_blocked_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_details_admin_community => (/:locale)/admin/communities/:id/update_details(.:format)
  // function(id, options)
  update_details_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_expiration_admin_custom_fields => (/:locale)/admin/custom_fields/update_expiration(.:format)
  // function(options)
  update_expiration_admin_custom_fields_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"update_expiration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// update_location_admin_custom_fields => (/:locale)/admin/custom_fields/update_location(.:format)
  // function(options)
  update_location_admin_custom_fields_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"update_location",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// update_person_payment_settings => (/:locale)/:person_id/settings/payments(.:format)
  // function(person_id, options)
  update_person_payment_settings_path: Utils.route([["locale",false],["person_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// update_price_admin_custom_fields => (/:locale)/admin/custom_fields/update_price(.:format)
  // function(options)
  update_price_admin_custom_fields_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"update_price",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_settings_admin_community => (/:locale)/admin/communities/:id/update_settings(.:format)
  // function(id, options)
  update_settings_admin_community_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"communities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_shape_int_api_listing => /int_api/listings/:id/update_shape(.:format)
  // function(id, options)
  update_shape_int_api_listing_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"int_api",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_shape",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_stripe_keys_admin_payment_preferences => (/:locale)/admin/payment_preferences/update_stripe_keys(.:format)
  // function(options)
  update_stripe_keys_admin_payment_preferences_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"payment_preferences",false],[2,[7,"/",false],[2,[6,"update_stripe_keys",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// update_working_time_slots_int_api_listing => /int_api/listings/:id/update_working_time_slots(.:format)
  // function(id, options)
  update_working_time_slots_int_api_listing_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"int_api",false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_working_time_slots",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_feedback => (/:locale)/user_feedbacks/:id(.:format)
  // function(id, options)
  user_feedback_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"user_feedbacks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// user_feedbacks => (/:locale)/user_feedbacks(.:format)
  // function(options)
  user_feedbacks_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"user_feedbacks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// verification_required_listings => (/:locale)/listings/verification_required(.:format)
  // function(options)
  verification_required_listings_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"listings",false],[2,[7,"/",false],[2,[6,"verification_required",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// webhooks_email_orders => /webhooks/email_orders(.:format)
  // function(options)
  webhooks_email_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"email_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// webhooks_iterable => /webhooks/iterable(.:format)
  // function(options)
  webhooks_iterable_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"iterable",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// webhooks_shopify_orders_create => /webhooks/shopify/orders_create(.:format)
  // function(options)
  webhooks_shopify_orders_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"shopify",false],[2,[7,"/",false],[2,[6,"orders_create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// webhooks_stripe_account_updated => /webhooks/stripe/account_updated(.:format)
  // function(options)
  webhooks_stripe_account_updated_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"account_updated",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// webhooks_typeform => /webhooks/typeform(.:format)
  // function(options)
  webhooks_typeform_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"typeform",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// wishlist_email_ok_webhooks_iterable => /webhooks/iterable/wishlist_email_ok(.:format)
  // function(options)
  wishlist_email_ok_webhooks_iterable_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"iterable",false],[2,[7,"/",false],[2,[6,"wishlist_email_ok",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
    root.Routes.options = defaults;
    root.Routes.default_serializer = function(object, prefix) {
      return Utils.default_serializer(object, prefix);
    };
    return root.Routes;
  };

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return createGlobalJsRoutesObject();
    });
  } else {
    createGlobalJsRoutesObject();
  }

}).call(this);
